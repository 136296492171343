import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { LoginService } from '../services/log_in.service';
import { Router } from '@angular/router';

@Injectable()
export class MiscService {

  constructor() {
  }

 

  getDateObject(date){
    if(date){
      dateObject=[];
      var datearray=date.split("/", 3); 
   
    var dateObject={};
    dateObject['year']=+datearray[2];
    dateObject['month']=+datearray[0];
    dateObject['day']=+datearray[1];
    return dateObject;
    }
    
    return null;
    
  }

}
