import { Component, OnInit, Input, ViewEncapsulation, Output, ViewChild, ElementRef, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { cardToggle, cardClose, cardIconToggle } from './card-animation';
import { PEFirmUserService } from "@app/shared/services/pefirm.service";
import { WebserviceService } from "@app/shared/services/webservice.service";
import swal from 'sweetalert2';
import { WindowScrolling } from '@app/shared/services/windowScrolling';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [cardToggle, cardClose, cardIconToggle],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() headerContent: string;
  @Input() title: string;
  @Input() NoCardButton: string;
  @Input() blockClass: string;
  @Input() cardClass: string;
  @Input() classHeader = false;
  @Input() cardOptionBlock = false;
  @Input() byDefaultHidden;
  @Input() ques_submitted;
  @Input() goToLinkInput=false;
  @Input() isPEAdminNewCompanyCard=false;
  @Input() isPEAdminNewCompanyCardObj;


// ques_submitted_flag=(this.ques_submitted)
  @Input('PEId') PEId: string;
  @Input('CompanyName') CompanyName: string;
  @Input('AccountType') AccountType: string;

  @Output() isFullCard = new EventEmitter<string>();
  @Output() secondChildClosedEvent = new EventEmitter<string>();
  @Output() peAddFirmCustomEvent = new EventEmitter();
  @Output() deletePECustomEvent = new EventEmitter();
  @Output() closeIconClicked = new EventEmitter();
  @Output() editCardObj = new EventEmitter();

  cardToggle = 'expanded';
  cardClose = 'open';
  fullCard: string;
  fullCardIcon: string;
  loadCard = false;
  isCardToggled = false;
  cardLoad: string;
  cardIconToggle: string;
  @ViewChild('cardBlock') private cardBlock: ElementRef;
  logoImg = 'assets/images/no_image.jpg';
  constructor(private PEFirmUserService: PEFirmUserService, private WebserviceService: WebserviceService, private WindowScrolling: WindowScrolling,private cookieService: CookieService,private router: Router,) {
    this.fullCardIcon = 'fa-eye';
    this.cardIconToggle = 'an-animate';
  }
goToToolTip="";
  ngOnInit() {
    if(this.isPEAdminNewCompanyCardObj){
      if(this.isPEAdminNewCompanyCardObj.logo) {
        this.logoImg = this.isPEAdminNewCompanyCardObj.logo
      }
    }
    if(this.AccountType == "Portfolio"){
      this.goToToolTip="Go To Portfolio Company Dashboard";
    }
    else if(this.AccountType == "PEFirm"){
      this.goToToolTip=" Go to PE Firm Dashboard";
    }


    if (this.cardOptionBlock) {
      this.cardToggle = 'false';

    }

    if (this.byDefaultHidden) {
      this.cardClose = 'closed';
    }
  }

  ngAfterViewInit() {

  }

  ngOnChanges() {
    if (this.byDefaultHidden == false) {
      console.log("card close called");
      console.log(this.cardBlock.nativeElement);
      // this.cardBlock.nativeElement.sc
      setTimeout(() => {
        this.cardBlock.nativeElement.scrollTop = 0;

      }, 500);

      this.WindowScrolling.disable()


      this.cardClose = 'open';
    }

    if (this.byDefaultHidden == true) {
      this.cardClose = 'closed';
      this.WindowScrolling.enable()
    }
  }

  toggleCard(event) {
    this.cardToggle = this.cardToggle === 'collapsed' ? 'expanded' : 'collapsed';
  }

  toggleCardOption() {
    this.isCardToggled = !this.isCardToggled;
    this.cardIconToggle = this.cardIconToggle === 'an-off' ? 'an-animate' : 'an-off';
  }

  closeCard(event) {

    if (this.AccountType == "PEFirm" || this.AccountType == "Portfolio") {
      console.log("called the account");
      this.PEFirmUserService.PEFirmId = this.PEId;
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#B8080F',
        cancelButtonColor: '#464545',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          console.log(this.PEId);
          this.deletePECustomEvent.emit(this.PEId);
          // this.personalContactsData.splice(this.personalContactsData.findIndex(x => x.id == id), 1);
          this.cardClose = this.cardClose === 'closed' ? 'open' : 'closed';

          if (this.byDefaultHidden == false && this.isCardToggled) {
            this.byDefaultHidden = true;

            this.toggleCardOption();

            this.peAddFirmCustomEvent.emit();
          }

          this.secondChildClosedEvent.emit(this.cardClose);
        }
      }).catch(function (error) { console.log(error) });


    }

  }

  editCard(event) {

    if (this.AccountType == "PEFirm" || this.AccountType == "Portfolio") {
      console.log("called the account");
      this.PEFirmUserService.PEFirmId = this.PEId;
      this.editCardObj.emit(this.PEId);


    }

  }
  goToLink(event) {
    console.log(event);
    
    // this.cookieService.set('userid', data.data.id);
    // this.cookieService.set('usertype', data.data.type);
    
    // this.cookieService.set('accessToken', data.data.accessToken);
    console.log(this.PEId);
    console.log(this.AccountType);
    if (this.AccountType == "Portfolio") {

    this.cookieService.set('association_id', this.PEId,null,'/');
    this.cookieService.set('accessUserType', "3",null,'/');
    this.cookieService.set('PortfolioName', this.CompanyName,null,'/');
    this.router.navigate(['pc-dashboard']);
    }
    else if (this.AccountType == "PEFirm") {
      this.cookieService.set('association_id', this.PEId,null,'/');
      this.cookieService.set('actualAssociation_id', this.PEId,null,'/');
    this.cookieService.set('accessUserType', "2",null,'/');
    this.cookieService.set('PEName', this.CompanyName,null,'/');
    this.router.navigate(['PEadmin']);
    }
    this.WindowScrolling.enable()

  }
  fullCardTitle = "View/Edit";
  fullScreen(event) {
    console.log("called full screen");

    this.fullCard = this.fullCard === 'full-card' ? '' : 'full-card';
    this.fullCardIcon = this.fullCardIcon === 'fa-eye' ? 'fa-times' : 'fa-eye';
    this.fullCardTitle = this.fullCardIcon === 'fa-eye' ? 'View/Edit' : 'Close';

    if (this.fullCardIcon === "fa-eye") {
      console.log("enable scrolling");
      this.WindowScrolling.enable()
    }
    else {
      console.log("disable scrolling");
      this.WindowScrolling.disable()
    }

    this.isFullCard.emit(this.fullCard);
    this.closeIconClicked.emit();

  }

  async GetDetailData(event) {
    console.log("Called the webservice");
    console.log("PEId==", this.PEId);
    console.log("AccountType==", this.AccountType);
    if (this.AccountType == "PEFirm" || this.AccountType == "Portfolio") {
      console.log("called the account");
      this.PEFirmUserService.PEFirmId = this.PEId;
      console.log("PEfirm Id==", this.PEFirmUserService.PEFirmId)

    }
    console.log(event);
  }

  cardRefresh() {
    this.loadCard = true;
    this.cardLoad = 'card-load';
    setTimeout(() => {
      this.cardLoad = '';
      this.loadCard = false;
    }, 3000);
  }
}
