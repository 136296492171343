import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  tempState = [];
  breadcrumbFlag=false;
  // breadcrumbs: Array<Object>;
  breadcrumbs=[];
  constructor(private router: Router, private route: ActivatedRoute,private cookieService: CookieService) {
    
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(() => {
        console.log("route",this.route.root);
        this.breadcrumbs = [];
        this.tempState = [];
        //Navi code starts
        // this.breadcrumbs.push({
        //   label: routes.snapshot.data.title,
        //   icon: icon,
        //   caption: caption,
        //   status: status,
        //   url: url
        // });
        //Ends
        let currentRoute = this.route.root,
            url = '';
        do {
          

          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              console.log("URL==",url);
              console.log("account type==",routes.snapshot.data.type);
              this.setCookiesPageLoad(routes.snapshot.data.type);
          if (!(this.breadcrumbs.length > 0)) {
            var usertype = this.getCookie("usertype");
            var accessUserType = this.getCookie("accessUserType");
            var portfolioName = this.getCookie("PortfolioName");
            var peName = this.getCookie("PEName");
            console.log("usertype=", usertype, "  accesstype==", accessUserType);
            if (usertype == "1" && accessUserType == "2") {
              this.breadcrumbFlag=true;
              console.log("Came in RedgateAdmin as PEadmin block");
              this.breadcrumbs.push({
                label: "Redgate Admin",
                status: status,
                url: "/admin",
                icon:"fa-home"
              },{
                label: "PE Admin ("+peName+")",
                status: status,
                url: "/PEadmin"
              });

            }
            else if (usertype == "1" && accessUserType == "3") {
              this.breadcrumbFlag=true;
              console.log("Came in RedgateAdmin as Portfolio block");
              this.breadcrumbs.push({
                label: "Redgate Admin",
                status: status,
                url: "/admin",
                icon:"fa-home"
              },{
                label: "PE Admin ("+peName+")",
                status: status,
                url: "/PEadmin"
              },
              {
                label: "Portfolio ("+portfolioName+")",
                status: status,
                url: "/pc-dashboard"
              });

            }
            else if (usertype == "2" && accessUserType == "3") {
              this.breadcrumbFlag=true;
              console.log("Came in PEAdmin as Portfolio block");
              this.breadcrumbs.push({
                label: "PE Admin ("+peName+")",
                status: status,
                url: "/PEadmin",
                icon:"fa-home"
              }, {
                label: "Portfolio ("+portfolioName+")",
                status: status,
                url: "/pc-dashboard"
              });

            }
            else
            this.breadcrumbFlag=false;
          }
             
              if (routes.snapshot.data.title !== undefined) {
                let status = true;
                if (routes.snapshot.data.status !== undefined) {
                  status = routes.snapshot.data.status;
                }

                let icon = false;
                if (routes.snapshot.data.icon !== undefined) {
                  icon = routes.snapshot.data.icon;
                }

                let caption = false;
                if (routes.snapshot.data.caption !== undefined) {
                  caption = routes.snapshot.data.caption;
                }

                if (!this.tempState.includes(routes.snapshot.data.title)) {
                  this.tempState.push(routes.snapshot.data.title);
                  this.breadcrumbs.push({
                    label: routes.snapshot.data.title,
                    icon: icon,
                    caption: caption,
                    status: status,
                    url: url
                  });
                }
              }
              if(routes.snapshot.data.noBreadcrumbs){
                console.log("came in no breadcrumbs");
                this.breadcrumbFlag=false;
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);
      });
  }
  setCookies(breadcrumbs){
    console.log(breadcrumbs);
    if(breadcrumbs.url=="/admin"){
      // this.cookieService.set('association_id', this.PEId);
      this.cookieService.set('accessUserType', "1",null,'/');
    }
   else if(breadcrumbs.url=="/PEadmin"){
      // this.cookieService.set('association_id', this.PEId);
      this.cookieService.set('accessUserType', "2");
      this.cookieService.set('association_id', this.getCookie("actualAssociation_id"),null,'/');
    }

  }
  setCookiesPageLoad(type){
    this.breadcrumbs = [];
    console.log("Account type",type);
    if(type=="1"){
      // this.cookieService.set('association_id', this.PEId);
      this.cookieService.set('accessUserType', "1",null,'/');
    }
   else if(type=="2"){
      // this.cookieService.set('association_id', this.PEId);
      this.cookieService.set('accessUserType', "2",null,'/');
      this.cookieService.set('association_id', this.getCookie("actualAssociation_id"),null,'/');
    }

  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    console.log("cookiesss");
    console.log(document.cookie);
    return "";
  }
}
