import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
// import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';

import { BreadcrumbsComponent, AdminComponent } from '@layout/admin';

import { MenuItemsSelectableService } from './shared/services/menuItemsService.service';
import { HttpService } from './shared/services/http.service';
import { WebserviceService } from '@app/shared/services/webservice.service';
import { PEFirmUserService } from '@app/shared/services/pefirm.service';
import {ToastyModule} from 'ng2-toasty';

import { ToastyService, ToastyConfig } from "ng2-toasty";
import { NgxSpinnerModule } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { IsAdmin } from './shared/services/is-admin.service';
import { IsPEAdmin } from './shared/services/is-peadmin.service';
import { IsLogin } from './shared/services/is-login.service';
import { IsPort } from './shared/services/is-port.service';
import { customCommunication } from '@app/shared/services/communication.service';
import { WindowScrolling } from '@app/shared/services/windowScrolling';
import { UploadFileService } from '@app/shared/services/upload-file.service';
import { NextPreviousModule} from '@app/theme/next-previous/next-previous.module';
import { RouterModule } from '@angular/router';
import { PendingChangesGuard } from './shared/services/pending-changes.guard';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Ng5SliderModule } from 'ng5-slider';
import { MiscService } from "@app/shared/services/misc_service.ts";




// import { MedicalPlanComponent } from './theme/medical-plan/medical-plan.component';


// import { NextPreviousComponent} from '@app/theme/next-previous/next-previous.component';





@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    RouterModule,
    NgxSpinnerModule,
    ToastyModule.forRoot(),
    NextPreviousModule,
    AngularEditorModule,
    Ng5SliderModule
     

  ],
  providers: [MenuItems, MenuItemsSelectableService, HttpService, WebserviceService, PEFirmUserService, ToastyService, ToastyConfig, CookieService, AuthGuard, IsAdmin, IsPEAdmin, IsLogin, customCommunication, WindowScrolling, IsPort,UploadFileService,PendingChangesGuard,MiscService],
  bootstrap: [AppComponent]
})
export class AppModule { }
