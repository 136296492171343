import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';


@Injectable()
export class UploadFileService {

  FOLDER = '';

  constructor() { }

  async uploadfile(file) {

    const bucket = new S3(
      {
        accessKeyId: 'AKIAINRJGEL5DWPUOZJA',
        secretAccessKey: 'LMtRn1ew09gPOPGhNNDSoWrK7a7MF3nKKAK3RSnQ',
        region: 'us-east-1'
      }
    );
    var filename = file.name.replace(/ /g, "_");
    const stamp = Math.round(new Date().getTime() / 1000);
    const params = {
      Bucket: 'redgateuploadedfiles',
      Key:  stamp + filename,
      Body: file,
      Acl: 'public-read'
    };
    // console.log("method starts");
    try {
      var responsestatus = await bucket.upload(params).promise();
      // console.log("ourput after", responsestatus);
      return responsestatus;

    } catch (error) {
      console.log("mesage:,", error)
    }
    // console.log("method complete");
  }




}
