import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild} from '@angular/router';
import { LoginService } from '../services/log_in.service';
import { Router } from '@angular/router';

@Injectable()
export class IsAdmin implements CanActivate, CanActivateChild {

  constructor(private login: LoginService, private router: Router) {
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    console.log("cookiesss");
    console.log(document.cookie);
    return "";
  }

  canActivate() {
    console.log('Can Activate Auth Guard Called!');
    console.log("Cookie Length: " + document.cookie.length);
    if (document.cookie.length !== 0) {
      var logincookie = this.getCookie("loginStatus");
      var usertype = this.getCookie("usertype");

      console.log("logincookievalue===" + logincookie);

      if (logincookie === "1") {
      if(usertype=="1")
      {
        return true;
      }
      else{
        this.router.navigate(['/login']);
        return false;
      }
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  

  canActivateChild() {
    console.log('checking child route access');
    return true;
  }

}
