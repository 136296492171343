import { Injectable } from '@angular/core';
import { Component, OnInit, Output, ViewChild, Renderer, ElementRef, HostListener } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpService } from '../../shared/services/http.service';
import { IOption } from 'ng-select';


import { WebserviceService } from '@app/shared/services/webservice.service';
import { ToastyService } from "ng2-toasty";
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';


@Injectable()

export class customCommunication {

    userType: string;
    userName;
    associationNameHeader:string;

    myFormGroup: FormGroup;



    constructor(private toast: ToastyService, public webapi: WebserviceService, private spinner: NgxSpinnerService) {


    }
    PEfirmDetailData;



}
