import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/mergeMap';
import { NgxSpinnerService } from 'ngx-spinner';
import { IsLogin } from "@app/shared/services/is-login.service";
import * as CryptoJS from 'crypto-js';
import { isDevMode } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Auth': finalAuth,
    'Access-Control-Allow-Origin': '*'
  })
};


@Injectable() 
export class WebserviceService {
  UserId: number;
  // private base_url = 'http://192.168.5.111/redgate/public/api/'; 
  // private base_url = 'https://54.86.16.225/redgate/public/index.php/api/'; 
  //  private base_url = 'http://54.86.16.225/redgate_test/public/index.php/api/'; 
  //  private base_url = 'http://54.161.93.49/redgate_test/public/index.php/api/';  
  //  private base_url = 'http://54.161.93.49/redgate/public/index.php/api/';  ////commented
  //  private base_url = 'http://192.168.43.118/redgate/public/index.php/api/'; 
  // private base_url = 'http://localhost/redgate/public/index.php/api/'; 
  private base_url = 'https://api.portfoliobenefitmanager.com/redgate/public/index.php/api/';
  // private base_url = 'http://redgate.hireconsultingwhiz.com/backend/api/'
    
  

  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private LoginService: IsLogin) {
    this.token = this.LoginService.getCookie("accessToken");
    if(isDevMode()) {
      if((localStorage.getItem('redgate_api_url'))) {       
        var u = localStorage.getItem('redgate_api_url')
        var l = u.substr(-1); 
        if (l !== '/') { 
          u = u+'/'
        } 
        this.base_url = u
      }
    }
  }
  token;
  makeRequest(url, postdata): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": this.token,
        // "rejectUnauthorized": "false"

      })
    };

    return this.http.post(this.base_url + url, postdata, httpOptions);
  }

  getProfileData(url, post_data): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": this.token

      })
    };

    return this.http.post(this.base_url + url, post_data, httpOptions)
      // .mergeMap(res1 => {
      //   console.log('getting first response-------|');
      //   console.log(res1);
      //   console.log('|---------end');
      //   //return this.http.post(this.base_url,post_data);
      //   return Observable;
      // })
      ;
  }

  getTokenData(url, post_data): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImIzODdlNTZmYzFmZTMyMjgxYmUxOThmY2Q0OTI0MTI1Mzk5ZTFlMTMzMmJlMDUxNjIyNGQxNTdkOWJjZDFjM2MyYjI1YzVlNWI4MTk0NDY1In0.eyJhdWQiOiIxIiwianRpIjoiYjM4N2U1NmZjMWZlMzIyODFiZTE5OGZjZDQ5MjQxMjUzOTllMWUxMzMyYmUwNTE2MjI0ZDE1N2Q5YmNkMWMzYzJiMjVjNWU1YjgxOTQ0NjUiLCJpYXQiOjE1MzgwMzgxNjYsIm5iZiI6MTUzODAzODE2NiwiZXhwIjoxNTY5NTc0MTY2LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.iTvauXVG8ZPQjxmHvtWVZCah3Hx5_5ID8rfTv_ilQQLuG2y42y-iwDHCZoe2-g6StkOpdfk_GI9IYGEmfIDfSa7OGQmYFuc2f2o_woMC42TwljG4QsiKmcMp4nA3niC1mIVj3cu_wygyZpMX-JJLLELEn0kM0v-6jzUgy7qI7gPCzYInsDpdbg77SJVCMCJp6ESIVI7RA_bmWM11R5xZaJ_q5I6Fda_xLPWbB3PRPslG1nzJVGiRiXdUXSWaQUJXl0T3XLJsYohfHrGC_-kqhQF_GiUQ89H7YhMvvS98VHwFgrdswYglGx16Udm7fX_nVGxEDvMB4asO9jyaIeTB2cQj1ly9o42uGns5o_heSj7168-0qJG9oV8xm8PqwmFc0cd6Z4gCMP-tmOQmveKmV_3PSS8RxSRoJYVBfNKiE5odn3ApR3DRB8r21YV-gcRO_QTu00dMmw-5saK5s4swP7SfkjNZg07WxT0QFiCLdk0DrdxLCnbAMhr5ksNX03yrD3diWgsGdEQomFSA8UxEp4e7uP9qxvprsoLwzFGjAkeCrkzy3sOsTEPbc1HY7O6BRv1kaT2shXj6bYG18J44xLJ5JCqoe6_CvUdIVoCCx0-OJu-L83KhI-2xtLFfCrHEE0ePXNwIk-1fPTEVab2IV2MmdMuG3Naowx3ZTS7rrKE"

      })
    };

    return this.http.post(this.base_url + url, post_data, httpOptions)

      ;
  }

  set(keys, value){

    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    console.log('encryption');
    console.log(encrypted.toString());
    return encrypted.toString();
  }

}