import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';
import {RouterModule, Router } from '@angular/router';
// import { MenuItems } from '../../shared/menu-items/menu-items';
import { HttpService } from '../../shared/services/http.service';
import { blankValidator } from "@app/shared/services/blankValidator";
import { WebserviceService } from "@app/shared/services/webservice.service";
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { AuthGuard } from "@app/shared/services/auth-guard.service";
import { ToastyService } from 'ng2-toasty';
import { NgxSpinnerService } from 'ngx-spinner';


import swal from 'sweetalert2';
import { e } from '@angular/core/src/render3';

@Component({
  selector: 'app-next-previous',
  templateUrl: './next-previous.html',
  styleUrls: ['./next-previous.scss',
    '../../../assets/icon/simple-line-icons-master/css/simple-line-icons.css'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])]
})
export class NextPreviousComponent implements OnInit, AfterViewInit {

  // @Input() obj;
  @Input() navigation;
  @Input() ques_submitted;
  @Input() is_submitted;
  @Input() countries;
  @Input() benefitScreen;

  validPath={
    "medical":"medical-plans",
    "dental":"dental-plans",
    "vision":"vision-plans",
    "basic_ad_d":"basic-life_add-plan",
    "optional_ad_d":"emp-dependent-plan",
    "short_term":"shortterm-disability-plan",
    "long_term":"longterm-disability-plan",
    "travel_accident":"business-travel-accident-plan",
    "us_expatriate":"US-expatriate-plan",
    "voluntary":"voluntary-plans",
    "key_person":"key-person-life-disability-plan",
    "exec_life":"executive-life-disability-plan",
    "group_personnel":"group-personal-excess-liability",
    "exec_health":"executive-health-exam",
    "administrator":"administrators-service-providers",
    "brokercommission":"Broker-Consultant-Compensation",
    "company_information":"company",
    "broker_information":"broker",
    "config_plan":"benefit-plan-configuration",
   
  }

  @ViewChild('infoTab') infoTab;
  @ViewChild('mainContainer') private mainContainer: ElementRef;

  @ViewChild('contactsTab') contactsTab;
  @Output() updatePEFirmCustomEvent = new EventEmitter();
  @Output() addContactCustomEvent = new EventEmitter();
  @Output() updateContactCustomEvent = new EventEmitter();
  @Output() deletePEProfileCustomEvent = new EventEmitter();
  @Output() deleteContactCustomEvent = new EventEmitter();
  @Output() questionnaireSubmitEvent = new EventEmitter();
  
  fullScreenValue: boolean = true;
  saveBtnVisibility: boolean;
 


  @Output() firstChildClosedEvent = new EventEmitter();

    constructor(private fBuilder: FormBuilder, public router: Router,  
      private http: HttpService, private WebserviceService: WebserviceService,
      private AuthGuard: AuthGuard,private toast: ToastyService,private spinner: NgxSpinnerService) {
      
   
        


  }



  Countries;
  Metadata;
  Positions; Status; industry; questionnaire; investment_type
  portfolio_company_id=this.AuthGuard.getCookie('association_id');
  ngOnInit() {
  
  
  }

  ngAfterViewInit() {

  }
  ngOnChanges(changes) {
    console.log("changes");
    console.log(changes);
   console.log(this.navigation);
   console.log(this.ques_submitted);
   console.log(this.is_submitted);
  }
 
  onNextClick(){
    var next="";
    var next_title="";
    console.log("next click")
    
    if(this.benefitScreen==1 && (this.navigation.questionnaire_status.company_information_status!=1 || this.navigation.questionnaire_status.configuration_questionnaire_status!=1 ||this.navigation.questionnaire_status.broker_information_status!=1  )){

      if(this.navigation.questionnaire_status.company_information_status!=1){
        next_title="Go To Company Information";
        next=this.validPath.company_information;
      }else{
        if(this.navigation.questionnaire_status.broker_information_status!=1){
          next_title="Go To Broker Information";
        next=this.validPath.broker_information;
        }else{
          var next="";
          var next_title="";
        }
      }

      if(next!=""){
        swal({
          title: 'The Company Information, Broker information and Benefit Plan Configuration pages of the questionnaire must be completed before proceeding to the next section of the questionnaire.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#B8080F',
          cancelButtonColor: '#464545',
          confirmButtonText: next_title,
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['./'+next]);
          }
        }).catch(function (error) { console.log(error) });
      }else{
        swal({
          title: 'The Company Information, Broker information and Benefit Plan Configuration pages of the questionnaire must be completed before proceeding to the next section of the questionnaire.',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#B8080F',
          // cancelButtonColor: '#464545',
          confirmButtonText: 'OK',
          // cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.value) {
           
          }
        }).catch(function (error) { console.log(error) });
      }
      
    }
    
  }

  submitQuestionnaire() {
    if(this.navigation.questionnaire_status.admin_service_provider_status==1&&
    this.navigation.questionnaire_status.ancillary_status==1&&
    this.navigation.questionnaire_status.broker_commission_status==1&&
    this.navigation.questionnaire_status.broker_information_status==1&&
    this.navigation.questionnaire_status.company_information_status==1&&
    this.navigation.questionnaire_status.configuration_questionnaire_status==1&&
    this.navigation.questionnaire_status.executive_status==1&&
    this.navigation.questionnaire_status.healthcare_status==1&&
    this.navigation.questionnaire_status.travel_status==1&&
    this.navigation.questionnaire_status.voluntary_status==1){
      
          swal({
            title: 'Do you want to submit the questionnaire?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B8080F',
            cancelButtonColor: '#464545',
            confirmButtonText: 'Yes, Submit it!'
          }).then((result) => {
            if (result.value) {
              this.spinner.show();
              // this.WebserviceService.makeRequest("Questionnaire/Submit", {"portfolio_company_id":this.navigation.questionnaire_status.portfolio_company_id,"reason":""}).subscribe(
              this.WebserviceService.makeRequest("Questionnaire/Submit", {"portfolio_company_id":this.portfolio_company_id,"reason":""}).subscribe(
                response => {
                  this.spinner.hide();
                  if (response.status_code == 200) {
                    console.log("response===",response);
                    this.questionnaireSubmitEvent.emit();
                    swal({
                      title: 'Questionnaire submitted successfully',
                      type: 'success',
                      // showCancelButton: true,
                      confirmButtonColor: '#B8080F',
                      // cancelButtonColor: '#464545',
                      // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                      if (result.value) {
                       
                      }
                    }).catch(function (error) { console.log(error) });
                    
    
                    this.toast.success({ title: response.message, timeout: 500000, theme: 'bootstrap' });
        
                  }
                  else
                  this.toast.warning({ title: response.message, timeout: 500000, theme: 'bootstrap' });
                  console.log(response);
                },
                error => { console.log(error); }
              );
              console.log(result.value);
            }
          }).catch(function (error) { console.log(error) });

    }
    else{
      // this.WebserviceService.makeRequest("Questionnaire/Check", {"portfolio_company_id":this.navigation.questionnaire_status.portfolio_company_id}).subscribe(
      this.WebserviceService.makeRequest("Questionnaire/Check", {"portfolio_company_id":this.portfolio_company_id}).subscribe(
        response => {
          this.spinner.hide();
          if (response.status_code == 200) {
            console.log("response===",response);
            var HTMLText='';
            HTMLText="<div style='text-align:left!important;padding-left:20px;'><b>You haven't completed this questionnaire yet." ;
            if(response.data.section.length>0){
              HTMLText+=" Below sections are incomplete:</b><ul style='list-style-type:disc!important;font-size:14px!important;padding-left:25px;'>";
              for (var i = 0; i < response.data.section.length; i++) {
                HTMLText+=  "<li>"+response.data.section[i]+"</li>";
              }
              HTMLText+="</ul><BR>";
            }

            if(response.data.error.length>0){
              console.log("came in if")
              
            
              
            HTMLText+="<B>Warnings:</b><ul  style='list-style-type:disc!important;font-size:14px!important;padding-left:20px;'>"
            for (var i = 0; i < response.data.error.length; i++) {
              HTMLText+=  "<li>"+response.data.error[i]+"</li>";
            }
            HTMLText+="</ul>"


            }
            HTMLText+="</div><BR><B>Please tell us why are you submitting this early?</b>";
              swal({
        title: 'Do you want to submit?',
        type: 'warning',
        text: "You haven't completed this questionnaire yet, Please tell us why are you submitting this early?",
        html:HTMLText,

        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#B8080F',
        cancelButtonColor: '#464545',
        confirmButtonText: 'Ok',
        input: "text",
        inputPlaceholder: 'Reason',
        inputValidator: (value) => {
          return !value && 'You need to write something!'
        }
      }).then((result) => {
        if (result.value) {
          this.spinner.show();

          console.log("resultvalue==",result.value);
          // this.WebserviceService.makeRequest("Questionnaire/Submit", {"portfolio_company_id":this.navigation.questionnaire_status.portfolio_company_id,"reason":result.value}).subscribe(
          this.WebserviceService.makeRequest("Questionnaire/Submit", {"portfolio_company_id":this.portfolio_company_id,"reason":result.value}).subscribe(
            response => {
              this.spinner.hide();
              if (response.status_code == 200) {
                console.log("response===",response);
                this.questionnaireSubmitEvent.emit();
                swal({
                  title: 'Questionnaire submitted successfully',
                  type: 'success',
                  // showCancelButton: true,
                  confirmButtonColor: '#B8080F',
                  // cancelButtonColor: '#464545',
                  // confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.value) {
                   
                  }
                }).catch(function (error) { console.log(error) });
                

                this.toast.success({ title: response.message, timeout: 500000, theme: 'bootstrap' });
    
              }
              else
              this.toast.warning({ title: response.message, timeout: 500000, theme: 'bootstrap' });
              console.log(response);
            },
            error => { console.log(error); }
          );
          console.log(result.value);
        }
      }).catch(function (error) { console.log(error) });
    }

    else
    this.toast.warning({ title: response.message, timeout: 500000, theme: 'bootstrap' });
    console.log(response);
  },
  error => { console.log(error); }
  );
  }

}

}
  

 



 



