import { Injectable } from '@angular/core';
@Injectable({providedIn: 'root'})
export class LoginService {

    // This is where your methods and properties go, for example: 

    LogInStatus:number;
    
    isLoggedIn(): boolean {
        return false;
      }

}
