import { AbstractControl } from '@angular/forms';
String.prototype.trim = function () {
    return this.replace(/^\s*/, "").replace(/\s*$/, "");
}
export function blankValidator(control: AbstractControl) {
    // console.log('control-value=' + control.value + "+");
    var value = control.value;
    if (value) {
        console.log(value.trim().length)
        // if (control.value == ' ') {
        if (!(value.trim().length)) {
            // control.value='';
            // control.setErrors({ required: true });
            // console.log('came in condition')
            return { required: true };

        }
    }
    // console.log('control-value=' + trim(control.value);
    // if (!control.value.startsWith('https') || !control.value.includes('.io')) {
    //     return { validUrl: true };
    // }
    return null;
}