import { Injectable } from "@angular/core";
import { WebserviceService } from "@app/shared/services/webservice.service";
import { AuthGuard } from "@app/shared/services/auth-guard.service";

import swal from 'sweetalert2';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  // icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

var MENUITEMS = [
  {
    label: "",
    main: [
      {
        state: "PEadmin",
        short_label: "N",
        name: "Portfolio Companies",
        type: "link",
        icon: "ti-layout-cta-right"
      }
      
    ]
  },
  {
    label: "",
    main: [
      
      
      {
        state: "user-management",
        short_label: "N",
        name: "User Management",
        type: "link",
        icon: "ti-bookmark-alt"
      },
      {
        state: "",
        short_label: "N",
        name: "Reports",
        icon: "ti-archive",
        type: "sub",
        children:[
          {
          state: "executive-report",
          short_label: "PCM",
          name: "Executive Summary Report",
          type: "link",
          icon: "ti-support"
        },
          {
          state: "premium-analysisreport",
          short_label: "PCM",
          name: "Premium Analysis Report",
          type: "link",
          icon: "ti-support"
        },
          { 
          state: "vendor-analysis-core-report",
          short_label: "PCM",
          name: "Vendor Analysis CORE Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-vbeb-report",
          short_label: "PCM",
          name: "Vendor Analysis VB&EB Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-services-report",
          short_label: "PCM",
          name: "Vendor Analysis Service Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "renewal-analysis-report",
          short_label: "PCM",
          name: "Renewal Date Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "key-vendor-premium-report",
          short_label: "PCM",
          name: "Key Vendor Premium Spend Summary Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-fee-analysis-report",
          short_label: "PCM",
          name: "Broker Fee / Commission Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-analysis2-report",
          short_label: "PCM",
          name: "Broker Analysis2 Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "summary-by-product-report",
          short_label: "PCM",
          name: "Summary By Product Report",
          type: "link",
          icon: "ti-support"
        }
      ]

      },
      // {
      //   state: "portfolio-companies",
      //   short_label: "N",
      //   name: "All Portfolio Companies",
      //   type: "link",
      //   icon: "ti-list"
      // },
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      },
      
      
    ]
  }
];
var MENUITEMSPEINADMIN = [
  {
    label: "",
    main: [
      {
        state: "PEadmin",
        short_label: "N",
        name: "Portfolio Companies",
        type: "link",
        icon: "ti-layout-cta-right"
      }
      
    ]
  },
  {
    label: "",
    main: [
      
      {
        state: "metadata",
        short_label: "N",
        name: "Metadata Configuration",
        type: "link",
        icon: "ti-pencil-alt"
      },
      {
        state: "user-management",
        short_label: "N",
        name: "User Management",
        type: "link",
        icon: "ti-bookmark-alt"
      },
      {
        state: "",
        short_label: "N",
        name: "Reports",
        icon: "ti-archive",
        type: "sub",
        children:[
          {
          state: "executive-report",
          short_label: "PCM",
          name: "Executive Summary Report",
          type: "link",
          icon: "ti-support"
        },
          {
          state: "premium-analysisreport",
          short_label: "PCM",
          name: "Premium Analysis Report",
          type: "link",
          icon: "ti-support"
        },
          { 
          state: "vendor-analysis-core-report",
          short_label: "PCM",
          name: "Vendor Analysis CORE Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-vbeb-report",
          short_label: "PCM",
          name: "Vendor Analysis VB&EB Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-services-report",
          short_label: "PCM",
          name: "Vendor Analysis Service Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "renewal-analysis-report",
          short_label: "PCM",
          name: "Renewal Date Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "key-vendor-premium-report",
          short_label: "PCM",
          name: "Key Vendor Premium Spend Summary Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-fee-analysis-report",
          short_label: "PCM",
          name: "Broker Fee / Commission Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-analysis2-report",
          short_label: "PCM",
          name: "Broker Analysis2 Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "summary-by-product-report",
          short_label: "PCM",
          name: "Summary By Product Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "stewardship-report",
          short_label: "PCM",
          name: "Stewardship Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "saving-target-report",
          short_label: "PCM",
          name: "Savings Target Report",
          type: "link",
          icon: "ti-support"
        }
      ]

      },
      {
        state: "portfolio-companies",
        short_label: "N",
        name: "All Portfolio Companies",
        type: "link",
        icon: "ti-list"
      },
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      },
      // {
      //   state: "profile",
      //   short_label: "N",
      //   name: "Profile",
      //   type: "link",
      //   icon: "ti-user"
      // },
      
    ]
  }
];


var MENUITEMSAdmin = [
  {
    label: "",
    main: [
      {
        state: "admin",
        short_label: "N",
        name: "Private Equity Firms",
        type: "link",
        icon: "ti-layout-cta-right"
      },
      {
        state: "benefit-plan-configuration-admin",
        short_label: "N",
        name: "Benefit Plan Configuration",
        type: "link",
        icon: "ti-settings"
      }     
    ]
  },
  {
    label: "",
    main: [
      
      {
        state: "metadata",
        short_label: "N",
        name: "Metadata Configuration",
        type: "link",
        icon: "ti-pencil-alt"
      },
      {
        state: "user-management",
        short_label: "N",
        name: "User Management",
        type: "link",
        icon: "ti-bookmark-alt"
      },
      {
        state: "",
        short_label: "N",
        name: "Reports",
        icon: "ti-archive",
        type: "sub",
        children:[
          {
          state: "executive-report",
          short_label: "PCM",
          name: "Executive Summary Report",
          type: "link",
          icon: "ti-support"
        },
          {
          state: "premium-analysisreport",
          short_label: "PCM",
          name: "Premium Analysis Report",
          type: "link",
          icon: "ti-support"
        },
          { 
          state: "vendor-analysis-core-report",
          short_label: "PCM",
          name: "Vendor Analysis CORE Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-vbeb-report",
          short_label: "PCM",
          name: "Vendor Analysis VB&EB Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-services-report",
          short_label: "PCM",
          name: "Vendor Analysis Service Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "renewal-analysis-report",
          short_label: "PCM",
          name: "Renewal Date Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "key-vendor-premium-report",
          short_label: "PCM",
          name: "Key Vendor Premium Spend Summary Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-fee-analysis-report",
          short_label: "PCM",
          name: "Broker Fee / Commission Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-analysis2-report",
          short_label: "PCM",
          name: "Broker Analysis2 Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "summary-by-product-report",
          short_label: "PCM",
          name: "Summary By Product Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "stewardship-report",
          short_label: "PCM",
          name: "Stewardship Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "saving-target-report",
          short_label: "PCM",
          name: "Savings Target Report",
          type: "link",
          icon: "ti-support"
        }
      ]

      },
      {
        state: "portfolio-companies",
        short_label: "N",
        name: "All Portfolio Companies",
        type: "link",
        icon: "ti-list"
      },
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      },
      // {
      //   state: "profile",
      //   short_label: "N",
      //   name: "Profile",
      //   type: "link",
      //   icon: "ti-user"
      // },
      
    ]
  }
];
var MENUITEMSPortfolio = [
  {
    label: "",
    main: [
      {
        state: "pc-dashboard",
        short_label: "N",
        name: "Dashboard",
        type: "link",
        icon: "ti-layout-cta-right"
      }
    ]
  },
  {
    label: "Questionnaire",
    main: [
      {
        state: "company",
        short_label: "PEFM",
        name: "Company Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "broker",
        short_label: "PCM",
        name: "Broker Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "benefit-plan-configuration",
        short_label: "N",
        name: "Benefit Plan Configuration",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "",
        short_label: "",
        name: "Healthcare Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Ancillary Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Travel Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Voluntary Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Executive Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Administrator and Service Providers",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Broker/Consultant Compensation",
        type: "",
        icon: ""
      }
      
    
    ]
  },
  {

    label: "Reports",
    icon: "ti-archive",
    type: "sub",
    main:[
      {
      state: "executive-report",
      short_label: "PCM",
      name: "Executive Summary Report",
      type: "link",
      icon: "ti-support"
    },
      {
      state: "premium-analysisreport",
      short_label: "PCM",
      name: "Premium Analysis Report",
      type: "link",
      icon: "ti-support"
    },
      { 
      state: "vendor-analysis-core-report",
      short_label: "PCM",
      name: "Vendor Analysis CORE Report",
      type: "link",
      icon: "ti-support"
    },
    {
      state: "vendor-analysis-vbeb-report",
      short_label: "PCM",
      name: "Vendor Analysis VB&EB Report",
      type: "link",
      icon: "ti-support"
    },
    {
      state: "vendor-analysis-services-report",
      short_label: "PCM",
      name: "Vendor Analysis Service Report",
      type: "link",
      icon: "ti-support"
    },
    { 
      state: "renewal-analysis-report",
      short_label: "PCM",
      name: "Renewal Date Analysis Report",
      type: "link",
      icon: "ti-support"
    },
    { 
      state: "key-vendor-premium-report",
      short_label: "PCM",
      name: "Key Vendor Premium Spend Summary Report",
      type: "link",
      icon: "ti-support"
    },
    { 
      state: "summary-by-product-report",
      short_label: "PCM",
      name: "Summary By Product Report",
      type: "link",
      icon: "ti-support"
    }
  ]

  },
  {
    label: "profile",

    main: [
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      }
    ]
  }
];

var MENUITEMSPortfolioINPE = [
  {
    label: "",
    main: [
      {
        state: "pc-dashboard",
        short_label: "N",
        name: "Dashboard",
        type: "link",
        icon: "ti-layout-cta-right"
      }
    ]
  },
  {
    label: "Questionnaire",
    main: [
      {
        state: "company",
        short_label: "PEFM",
        name: "Company Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "broker",
        short_label: "PCM",
        name: "Broker Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "benefit-plan-configuration",
        short_label: "N",
        name: "Benefit Plan Configuration",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "",
        short_label: "",
        name: "Healthcare Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Ancillary Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Travel Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Voluntary Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Executive Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Administrator and Service Providers",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Broker/Consultant Compensation",
        type: "",
        icon: ""
      }
      
    
    ]
  },
  {
    label: "",
    main: [
      
      
      {
        state: "user-management",
        short_label: "N",
        name: "User Management",
        type: "link",
        icon: "ti-bookmark-alt"
      },
      {
        state: "",
        short_label: "N",
        name: "Reports",
        icon: "ti-archive",
        type: "sub",
        children:[
          {
          state: "executive-report",
          short_label: "PCM",
          name: "Executive Summary Report",
          type: "link",
          icon: "ti-support"
        },
          {
          state: "premium-analysisreport",
          short_label: "PCM",
          name: "Premium Analysis Report",
          type: "link",
          icon: "ti-support"
        },
          { 
          state: "vendor-analysis-core-report",
          short_label: "PCM",
          name: "Vendor Analysis CORE Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-vbeb-report",
          short_label: "PCM",
          name: "Vendor Analysis VB&EB Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-services-report",
          short_label: "PCM",
          name: "Vendor Analysis Service Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "renewal-analysis-report",
          short_label: "PCM",
          name: "Renewal Date Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "key-vendor-premium-report",
          short_label: "PCM",
          name: "Key Vendor Premium Spend Summary Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-fee-analysis-report",
          short_label: "PCM",
          name: "Broker Fee / Commission Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-analysis2-report",
          short_label: "PCM",
          name: "Broker Analysis2 Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "summary-by-product-report",
          short_label: "PCM",
          name: "Summary By Product Report",
          type: "link",
          icon: "ti-support"
        }
      ]

      },
      
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      },
      
      
    ]
  }
];
var MENUITEMSPortfolioINADMIN= [
  {
    label: "",
    main: [
      {
        state: "pc-dashboard",
        short_label: "N",
        name: "Dashboard",
        type: "link",
        icon: "ti-layout-cta-right"
      }
    ]
  },
  {
    label: "Questionnaire",
    main: [
      {
        state: "company",
        short_label: "PEFM",
        name: "Company Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "broker",
        short_label: "PCM",
        name: "Broker Information",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "benefit-plan-configuration",
        short_label: "N",
        name: "Benefit Plan Configuration",
        type: "link",
        icon: "ti-check"
      },
      {
        state: "",
        short_label: "",
        name: "Healthcare Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Ancillary Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Travel Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Voluntary Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Executive Benefit Plans",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Administrator and Service Providers",
        type: "",
        icon: ""
      },
      {
        state: "",
        short_label: "",
        name: "Broker/Consultant Compensation",
        type: "",
        icon: ""
      }
      
    
    ]
  },
  {
    label: "",
    main: [
      
      {
        state: "metadata",
        short_label: "N",
        name: "Metadata Configuration",
        type: "link",
        icon: "ti-pencil-alt"
      },
      {
        state: "user-management",
        short_label: "N",
        name: "User Management",
        type: "link",
        icon: "ti-bookmark-alt"
      },
      {
        state: "",
        short_label: "N",
        name: "Reports",
        icon: "ti-archive",
        type: "sub",
        children:[
          {
          state: "executive-report",
          short_label: "PCM",
          name: "Executive Summary Report",
          type: "link",
          icon: "ti-support"
        },
          {
          state: "premium-analysisreport",
          short_label: "PCM",
          name: "Premium Analysis Report",
          type: "link",
          icon: "ti-support"
        },
          { 
          state: "vendor-analysis-core-report",
          short_label: "PCM",
          name: "Vendor Analysis CORE Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-vbeb-report",
          short_label: "PCM",
          name: "Vendor Analysis VB&EB Report",
          type: "link",
          icon: "ti-support"
        },
        {
          state: "vendor-analysis-services-report",
          short_label: "PCM",
          name: "Vendor Analysis Service Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "renewal-analysis-report",
          short_label: "PCM",
          name: "Renewal Date Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "key-vendor-premium-report",
          short_label: "PCM",
          name: "Key Vendor Premium Spend Summary Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-fee-analysis-report",
          short_label: "PCM",
          name: "Broker Fee / Commission Analysis Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "broker-analysis2-report",
          short_label: "PCM",
          name: "Broker Analysis2 Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "summary-by-product-report",
          short_label: "PCM",
          name: "Summary By Product Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "stewardship-report",
          short_label: "PCM",
          name: "Stewardship Report",
          type: "link",
          icon: "ti-support"
        },
        { 
          state: "saving-target-report",
          short_label: "PCM",
          name: "Savings Target Report",
          type: "link",
          icon: "ti-support"
        }
      ]

      },
      {
        state: "portfolio-companies",
        short_label: "N",
        name: "All Portfolio Companies",
        type: "link",
        icon: "ti-list"
      },
      {
        state: "profile",
        short_label: "N",
        name: "Profile",
        type: "link",
        icon: "ti-user"
      },
      // {
      //   state: "profile",
      //   short_label: "N",
      //   name: "Profile",
      //   type: "link",
      //   icon: "ti-user"
      // },
      
    ]
  }
];


@Injectable()
export class MenuItems {

  constructor(private WebserviceService: WebserviceService,private AuthGuard:AuthGuard){
   
    // MENUITEMSPortfolio[1]['main'][1].icon='ti-info';
    // MENUITEMSPortfolio=[...MENUITEMSPortfolio];
  }
  portfolio_company_id=this.AuthGuard.getCookie('association_id');
  public config_list;

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // getAll(): Menu[] {
  //   var uType = this.getCookie("usertype");
  //   var accessUserType = this.getCookie("accessUserType");
  //   if (uType == "1" && accessUserType=="1") {
  //     return MENUITEMSAdmin;
  //   // } else if (uType == "2" || (uType=="1" && accessUserType=='2')) {
  //   } else if((uType == "2" && accessUserType=='2') || (uType == "1" && accessUserType=='2')) {
  //     return MENUITEMS;
  //   } else {
  //     return MENUITEMSPortfolio;
  //   }
  //   // return MENUITEMS;
  // }

   getAll(): Menu[] {
    var uType = this.getCookie("usertype");
    var accessUserType = this.getCookie("accessUserType");
    switch(uType){
      case "1":
              switch(accessUserType){
               case"1":
                      return MENUITEMSAdmin;
                      
               case"2":
                      return MENUITEMSPEINADMIN;
               case"3":
                      return MENUITEMSPortfolioINADMIN;
              }
              break;
      case "2":   
              switch(accessUserType){
                case"2":
                      return MENUITEMS;
                case"3":
                      return MENUITEMSPortfolioINPE
              }
                break;
      
      case "3":
              return MENUITEMSPortfolio;

    }
  }

  updateMenuIcons(statusArray){
    MENUITEMSPortfolio[1]['main'][0].icon='ti-info';
    console.log('statusArray')
    console.log(statusArray)
   Object.keys(statusArray).forEach(key=> {
     let name='';
     let index=-1;
     switch(key){
       case 'company_information_status':
       name='Company Information';
       break;
       case 'configuration_questionnaire_status':
       name='Benefit Plan Configuration';
       break;
       case 'broker_information_status':
       name='Broker Information';
       break;
       case 'broker_commission_status':
       name='Broker/Consultant Compensation';
       break;
       case 'healthcare_status':
       name='Healthcare Benefit Plans';
       break;
       case 'ancillary_status':
       name='Ancillary Benefit Plans';
       break;
       case 'travel_status':
        name='Travel Benefit Plans';
        break;
       case 'voluntary_status':
        name='Voluntary Plans';
        break;
       case 'executive_status':
        name='Executive Benefit Plans';
        break;
       case 'admin_service_provider_status':
        name='Administrator and Service Providers';
        break;
      }
    
        
      index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == name);
    //  MENUITEMSPortfolio[1]['main'][index].icon=(statusArray[key]==0)?"ti-info":"ti-check";
   
    if(index!=-1){
      MENUITEMSPortfolio[1]['main'][index].icon=this.getIcon(statusArray[key]);
    }
     
     
 
   });
   MENUITEMSPortfolioINPE[1]['main']=MENUITEMSPortfolio[1]['main'];
  MENUITEMSPortfolioINADMIN[1]['main']=MENUITEMSPortfolio[1]['main'];
  
   if(statusArray.company_information_status==1 && 
    statusArray.broker_information_status==1 &&
    statusArray.broker_commission_status==1 &&
    statusArray.configuration_questionnaire_status==1 &&
    statusArray.healthcare_status==1 &&
    statusArray.ancillary_status==1 &&
    statusArray.travel_status==1 &&
    statusArray.voluntary_status==1 &&
    statusArray.executive_status==1 &&
    statusArray.admin_service_provider_status==1){
      swal({
        title: 'You have completed your Questionnaire. You can submit the questionnaire now.',
        type: 'success',
        // showCancelButton: true,
        confirmButtonColor: '#B8080F',
        cancelButtonColor: '#464545',
        // confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
         
        }
      }).catch(function (error) { console.log(error) });
    }
   return 1;
  }

  updateSideBar(){
    this.portfolio_company_id=this.AuthGuard.getCookie('association_id');
    console.log("callled itseld");
    this.WebserviceService.makeRequest("Portfoliodashboard", {'portfolio_company_id':this.portfolio_company_id}).subscribe(
      response => {
        if (response.status_code == 200) {
          this.config_list=response.data.config_list;
          let questStatus=response.data.questionnaire_status;
          console.log("success");
          // comment later
          // questStatus.configuration_questionnaire_status=1;
          // comment later


          let comp_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Company Information');
          //  MENUITEMSPortfolio[1]['main'][comp_index].icon=(questStatus['company_information_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][comp_index].icon=this.getIcon(questStatus['company_information_status']);

           let config_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Benefit Plan Configuration');
          //  MENUITEMSPortfolio[1]['main'][config_index].icon=(questStatus['configuration_questionnaire_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][config_index].icon=this.getIcon(questStatus['configuration_questionnaire_status']);

           let broker_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Broker Information');
          //  MENUITEMSPortfolio[1]['main'][broker_index].icon=(questStatus['broker_information_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][broker_index].icon=this.getIcon(questStatus['broker_information_status']);
          
          if(questStatus.company_information_status==1 && questStatus.configuration_questionnaire_status==1 && questStatus.broker_information_status==1){

          
          //sidebar code for healthcare
          if(this.config_list.healthcare.status==1){
            let healthArray={
              state: "healthcare-plans",
              short_label: "PCM",
              name: "Healthcare Benefit Plans",
              // type: 'link',
              type: "sub",
              icon: this.getIcon(questStatus.healthcare_status),
              // icon: (questStatus.healthcare_status==0)?"ti-info":"ti-check",
              children:[{
                state: "healthcare-plans",
                short_label: "PCM",
                name: "Dashboard",
                type: "link",
                icon: "ti-support"
              }]
            };
            console.log("callled out");
            healthArray=this.getHealthcareMenu(this.config_list.healthcare,healthArray);
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Healthcare Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(healthArray);
            }else{
              
              MENUITEMSPortfolio[1]['main'][index]=healthArray;
            }
           
          }else{
            console.log("delted");
            let healthArray={
              state: "",
              short_label: "",
              name: "Healthcare Benefit Plans",
              type: "",
              icon: ""
            }
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Healthcare Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(healthArray);
            }else{
              
              MENUITEMSPortfolio[1]['main'][index]=healthArray;
            }
          }
          //sidebar code for anciallary
          if(this.config_list.ancillary.status==1){
            let ancillaryArray={
              state: "ancillary-plans",
              short_label: "PCM",
              name: "Ancillary Benefit Plans",
              type: "sub",
              // icon: (questStatus.ancillary_status==0)?"ti-info":"ti-check",
              icon: this.getIcon(questStatus.ancillary_status),
              children: [
                {
                  state: "ancillary-plans",
                  short_label: "PCM",
                  name: "Dashboard",
                  type: "link",
                  icon: "ti-support"
                }]
              };
            console.log("callled out");
            ancillaryArray=this.getAncillaryMenu(this.config_list.ancillary,ancillaryArray);
            
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Ancillary Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(ancillaryArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=ancillaryArray;
            }
          }else{
            let ancillaryArray=
            {
                    state: "",
                    short_label: "",
                    name: "Ancillary Benefit Plans",
                    type: "",
                    icon: ""
                  }
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Ancillary Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(ancillaryArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=ancillaryArray;
            } 
          }

          //sidebar code for travel
          if(this.config_list.travel.status==1){
            console.log("travelicon"+this.getIcon(questStatus.travel_status));
            let travelArray={
              state: "travel-benefit-plans",
              short_label: "PCM",
              name: "Travel Benefit Plans",
              type: "sub",
              // icon: (questStatus.travel_status==0)?"ti-info":"ti-check",
              icon:  this.getIcon(questStatus.travel_status),
              children: [
                {
                  state: "travel-benefit-plans",
                  short_label: "PCM",
                  name: "Dashboard",
                  type: "link",
                  icon: "ti-briefcase"
                }]
              };
            console.log("callled out");
            travelArray=this.getTravelMenu(this.config_list.travel,travelArray);
            
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Travel Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(travelArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=travelArray;
            }
          }else{
            console.log("travelicon"+this.getIcon(questStatus.travel_status));
            let travelArray={
              state: "",
              short_label: "",
              name: "Travel Benefit Plans",
              type: "",
              icon: ""
            }
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Travel Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(travelArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=travelArray;
            }
          }
          
          //sidebar code for voluntary
          if(this.config_list.voluntary.status==1){
            let voluntaryArray={
              state: "voluntary-plans",
              short_label: "PCM",
              name: "Voluntary Plans",
              type: "link",
              // icon:(questStatus.voluntary_status==0)?"ti-info":"ti-check",
              icon:this.getIcon(questStatus.voluntary_status),
            }
            // console.log("callled out");
            // healthArray=this.getTravelMenu(this.config_list.travel,travelArray);
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Voluntary Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(voluntaryArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=voluntaryArray;
            }
            
          }else{
            let voluntaryArray= {
              state: "",
              short_label: "",
              name: "Voluntary Plans",
              type: "",
              icon: ""
            }
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Voluntary Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(voluntaryArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=voluntaryArray;
            }
          }

          //sidebar code for executive
          if(this.config_list.executive.status==1){

            let executiveArray={
              state: "executive-benefit-plans",
              short_label: "PCM",
              name: "Executive Benefit Plans",
              type: "sub",
              // icon: (questStatus.executive_status==0)?"ti-info":"ti-check",
              icon: this.getIcon(questStatus.executive_status),
              children: [
                {
                  state: "executive-benefit-plans",
                  short_label: "PCM",
                  name: "Dashboard",
                  type: "link",
                  icon: "ti-support"
                }]
              };
            console.log("callled out");
            executiveArray=this.getExecutiveMenu(this.config_list.executive,executiveArray);
            
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Executive Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(executiveArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=executiveArray;
            }
          }else{
            let executiveArray= {
              state: "",
              short_label: "",
              name: "Executive Benefit Plans",
              type: "",
              icon: ""
            }
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Executive Benefit Plans");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(executiveArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=executiveArray;
            }
          }

           //sidebar code for admin service

           if(this.config_list.admin_service_provider.status==1){
            let adminServiceArray= {
              state: "administrators-service-providers",
              short_label: "PCM",
              name: "Administrator and Service Providers",
              type: "link",
              // icon: (questStatus.admin_service_provider_status==0)?"ti-info":"ti-check",
              icon: this.getIcon(questStatus.admin_service_provider_status),
            }
            // console.log("callled out");
            // healthArray=this.getTravelMenu(this.config_list.travel,travelArray);
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Administrator and Service Providers");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(adminServiceArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=adminServiceArray;
            }
            
          }else{
            let adminServiceArray={
              state: "",
              short_label: "",
              name: "Administrator and Service Providers",
              type: "",
              icon: ""
            } 
            let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Administrator and Service Providers");
            
            if(index==-1){
              MENUITEMSPortfolio[1]['main'].push(adminServiceArray);
            }else{
              MENUITEMSPortfolio[1]['main'][index]=adminServiceArray;
            }
          }

            //sidebar code for broker commission
            // if(this.config_list.broker_commission_status.status==1){
              var brokerCommissionArray= {
                state: "Broker-Consultant-Compensation",
                short_label: "PCM",
                name: "Broker/Consultant Compensation",
                type: "link",
                // icon: (questStatus.broker_commission_status==0)?"ti-info":"ti-check",
                icon: this.getIcon(questStatus.broker_commission_status),
              }
              // console.log("callled out");
              // healthArray=this.getTravelMenu(this.config_list.travel,travelArray);
              let index=MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == "Broker/Consultant Compensation");
              
              if(index==-1){
                MENUITEMSPortfolio[1]['main'].push(brokerCommissionArray);
              }else{
                MENUITEMSPortfolio[1]['main'][index]=brokerCommissionArray;
              }
              
            // }
          }else{
            let main=[
              {
                state: "company",
                short_label: "PEFM",
                name: "Company Information",
                type: "link",
                icon: "ti-check"
              },
              {
                state: "broker",
                short_label: "PCM",
                name: "Broker Information",
                type: "link",
                icon: "ti-check"
              },
              {
                state: "benefit-plan-configuration",
                short_label: "N",
                name: "Benefit Plan Configuration",
                type: "link",
                icon: "ti-check"
              },
              {
                state: "",
                short_label: "",
                name: "Healthcare Benefit Plans",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Ancillary Benefit Plans",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Travel Benefit Plans",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Voluntary Plans",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Executive Benefit Plans",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Administrator and Service Providers",
                type: "",
                icon: ""
              },
              {
                state: "",
                short_label: "",
                name: "Broker/Consultant Compensation",
                type: "",
                icon: ""
              }
              
            
            ]
            MENUITEMSPortfolio[1]['main']=main;
            let comp_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Company Information');
          //  MENUITEMSPortfolio[1]['main'][comp_index].icon=(questStatus['company_information_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][comp_index].icon=this.getIcon(questStatus['company_information_status']);

           let config_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Benefit Plan Configuration');
          //  MENUITEMSPortfolio[1]['main'][config_index].icon=(questStatus['configuration_questionnaire_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][config_index].icon=this.getIcon(questStatus['configuration_questionnaire_status']);

           let broker_index= MENUITEMSPortfolio[1]['main'].findIndex(x => x.name == 'Broker Information');
          //  MENUITEMSPortfolio[1]['main'][broker_index].icon=(questStatus['broker_information_status']==0)?"ti-info":"ti-check";
           MENUITEMSPortfolio[1]['main'][broker_index].icon=this.getIcon(questStatus['broker_information_status']);
          }
          console.log("BEFORE menu");
          console.log(MENUITEMSPortfolio);
          // MENUITEMSPortfolio=[...MENUITEMSPortfolio];
          console.log("menu");
          console.log(MENUITEMSPortfolio);
          MENUITEMSPortfolioINPE[1]['main']=MENUITEMSPortfolio[1]['main'];
          MENUITEMSPortfolioINADMIN[1]['main']=MENUITEMSPortfolio[1]['main'];
        } console.log(response);
        
        //  console.log("this is from the side bar service");
      },
      error => {console.log("errrrrrrrrrrror"); console.log(error); }
    );
  }

   getHealthcareMenu(healthcare,healthArray) {

     console.log("callled in");
     if(healthcare.sub_category.medical==1){
       var medical={
        state: "medical-plans",
        short_label: "PCM",
        name: "Medical Plans",
        type: "link",
        icon: "ti-support",
        class:"active"
      }
      healthArray.children.push(medical)
     }
     if(healthcare.sub_category.dental==1){
      var dental={
              state: "dental-plans",
              short_label: "PCM",
              name: "Dental Plans",
              type: "link",
              icon: "ti-support"
            }
     healthArray.children.push(dental)
    }
    if(healthcare.sub_category.vision==1){
      var vision={
              state: "vision-plans",
              short_label: "PCM",
              name: "Vision Plans",
              type: "link",
              icon: "ti-support"
            }
     healthArray.children.push(vision)
    }
  return healthArray;
  }
  getAncillaryMenu(listArray,sidebarArrray) {

     console.log("callled in");
     if(listArray.sub_category.basic_ad_d==1){
       var basicAd={
        state: "basic-life_add-plan",
        short_label: "PCM",
        name: "Basic Life & AD&D Plans",
        type: "link",
        icon: "ti-support"
      }
      sidebarArrray.children.push(basicAd)
     }
     if(listArray.sub_category.optional_ad_d==1){
      var optionalAd={
        state: "emp-dependent-plan",
        short_label: "PCM",
        name: "Optional Employee & Dependent Life & AD&D Plans",
        type: "link",
        icon: "ti-support"
      }
            sidebarArrray.children.push(optionalAd)
    }
    if(listArray.sub_category.short_term==1){
      var shorterm={
        state: "shortterm-disability-plan",
        short_label: "PCM",
        name: "Short Term Disability Plans",
        type: "link",
        icon: "ti-support"
      }
            sidebarArrray.children.push(shorterm)
    }
    if(listArray.sub_category.long_term==1){
      var longterm={
        state: "longterm-disability-plan",
        short_label: "PCM",
        name: "Long Term Disability Plans",
        type: "link",
        icon: "ti-support"
      }
            sidebarArrray.children.push(longterm)
    }
  return sidebarArrray;
  }

  getTravelMenu(listArray,sidebarArrray) {

    console.log("callled in");
    if(listArray.sub_category.travel_accident==1){
      var travel={
        state: "business-travel-accident-plan",
        short_label: "PCM",
        name: "Business Travel Accident Plans",
        type: "link",
        icon: "ti-briefcase"
      }
     sidebarArrray.children.push(travel)
    }
    if(listArray.sub_category.us_expatriate==1){
     var expat={
      state: "US-expatriate-plan",
      short_label: "PCM",
      name: "US Expatriate Plans",
      type: "link",
      icon: "ti-briefcase"
    }
           sidebarArrray.children.push(expat)
   }
   
 return sidebarArrray;
 }
 getExecutiveMenu(listArray,sidebarArrray) {

    console.log("callled in");
    if(listArray.sub_category.key_person==1){
      var key_person={
        state: "key-person-life-disability-plan",
        short_label: "PCM",
        name: "Key Person Life & Disability Plans",
        type: "link",
        icon: "ti-support"
      }
     sidebarArrray.children.push(key_person)
    }
    if(listArray.sub_category.exec_life==1){
     var exec_life={
      state: "executive-life-disability-plan",
      short_label: "PCM",
      name: "Executive Life & Disability Plans",
      type: "link",
      icon: "ti-support"
    }
           sidebarArrray.children.push(exec_life)
  }
    if(listArray.sub_category.group_personnel==1){
     var group_personnel={
      state: "group-personal-excess-liability",
      short_label: "PCM",
      name: "Group Personal Excess Liability Plans",
      type: "link",
      icon: "ti-support"
    }
           sidebarArrray.children.push(group_personnel)
   }

   if(listArray.sub_category.exec_health==1){
    var exec_health= {
      state: "executive-health-exam",
      short_label: "PCM",
      name: "Executive Health Exam Plans",
      type: "link",
      icon: "ti-support"
    }
          sidebarArrray.children.push(exec_health)
  }
   
 return sidebarArrray;
 }

 getIcon(status){
   console.log(status.toString())
  let icon='';
  switch(status.toString()){
    case '0': 
    icon= 'ti-info';
    break;
    case '1':
    icon='ti-check';
    break;
    case '-1':
    icon= 'ti-close';
    break;
    default:
    icon= 'ti-info';

  }
  return icon;
 }


}
