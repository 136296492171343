import { Injectable } from '@angular/core';
import { Component, OnInit, Output, ViewChild, Renderer, ElementRef, HostListener } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpService } from '../../shared/services/http.service';
import { IOption } from 'ng-select';


import { WebserviceService } from '@app/shared/services/webservice.service';
import { ToastyService } from "ng2-toasty";
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { CustomPeComponent } from "@app/theme/custom-pe/custom-pe.component";
export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}
export function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}


@Injectable()

export class PEFirmUserService {

    userType: string;

    myFormGroup: FormGroup;
    @ViewChild(CustomPeComponent) CustomPeComponent: CustomPeComponent;


    constructor(private toast: ToastyService, public webapi: WebserviceService, private spinner: NgxSpinnerService) {

        console.log("files3data")
        // var url = (this.uploadService.getSignURL());
        // url.then((rs) => {
        //   console.log("rs value");
        //   console.log(rs)
        //   this.profileImage = rs;
        //   // here you will have your `rs`
        // });


    }
    PEfirmDetailData;
    public GetPeFirmData(id) {
        this.spinner.show();
        this.webapi.makeRequest('Pefirm/Show/' + id, {}).subscribe(
            response => {
                console.log(response.status);
                console.log("post response");
                console.log(response);
                if (response.status_code == 200) {
                    this.PEfirmDetailData = response.data[0];
                }
                this.spinner.hide();


            },
            error => { console.log(error); }
        );
    }
    PEfirmListData;
    PEFirmId;
    Countries;
    PEActions;
    PCActions;
    Positions
    Status
    Metadata;
    industry;
    Brokers;
    questionnaire;
    RelationshipManager;
    sub_industries;
    investment_type;
    public GetPeFirmList() {
        this.spinner.show();
        return this.webapi.makeRequest('Pefirm/List/', { user_id: 0, type: 1 });
    }



    // public async updatePE(data) {
    //     // this.spinner.show();
    //     console.log(data);


    //     // console.log(JSON.stringify(this.userDataPost));
    //     this.webapi.makeRequest('Pefirm/Update', data).subscribe(
    //         response => {
    //             console.log(response.status);
    //             console.log("post response");
    //             console.log(response);
    //             this.spinner.hide();
    //             // this.CustomPeComponent.closeForm('infotab-selectbyid1', true, false);
    //             this.toast.success({ title: "User updated successfully", timeout: 5000, theme: 'bootstrap' });

    //         },
    //         error => { console.log(error); }
    //     );
    // }



    Export() {
        this.spinner.show();
        var selectedusers = [];

        this.webapi.makeRequest('user/export', { "Data": 'navi' }).subscribe(
            response => {
                this.spinner.hide();
                console.log(response)
                console.log(response.fileLink)
                if (response.fileLink) {
                    window.open(
                        response.fileLink,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                }
                console.log("invoice receipt response");
                console.log(response);

            },
            error => { console.log(error); }
        );
    }


}
