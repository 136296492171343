import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => Promise<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    console.log(component)
    // if there are no pending changes, just allow deactivation; else confirm first
    console.log("component return data=",component.canDeactivate() );
    return from(component.canDeactivate());
    // return component.canDeactivate() ?
    //   true :
    //   // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    //   // when navigating away from your angular app, the browser will show a generic warning message
    //   // see http://stackoverflow.com/a/42207299/7307355
    //   confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
}
