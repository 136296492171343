import {
  Directive, HostBinding, Inject, Input, OnInit, OnDestroy
} from '@angular/core';

import { AccordionDirective } from './accordion.directive';
import { RouterStateSnapshot, Router } from '@angular/router';

@Directive({
  selector: '[appAccordionLink]'
})
export class AccordionLinkDirective implements OnInit, OnDestroy {

  @Input() public group: any;
  
 @Input() set children(child: any[]){
  const url = this.router.routerState.snapshot.url;
  // console.log('navichild:=',child,this.router.routerState.snapshot.url);
  if(child){
    // console.log("came in if")
    child.forEach(c => {
      // console.log("cstate=",c.state,"url==",url);
      
      if ("/"+c.state === url) {
        this.open = true;
        this._open=true;
      }
    })

  }
  else{
    this.open=false;

  }
}

  @HostBinding('class.pcoded-trigger')
  @Input()
  get open(): boolean {
    // console.log('getopen;')
//     if(this.children){
//       const url = this.router.routerState.snapshot.url;
//       // console.log("came in if")
//       this.children.forEach(c => {
//         // console.log("cstate=",c.state,"url==",url);
        
//         if ("/"+c.state === url) {
//           // this.open = true;
//           this._open=true;
//         }
//       })
//       // console.log("come in if",this._open)
//     }
//     else{
// // console.log("come in else",this._open)
//       this._open = false;
//     }
    return this._open;
  }

  set open(value: boolean) {
    // console.log("open called",value);
    this._open=value;
    
    /*for slimscroll on and off*/
    document.querySelector('.pcoded-inner-navbar').classList.toggle('scroll-sidebar');
    if (value) {
        this.nav.closeOtherLinks(this);
    }
  }

  protected _open: boolean=false;
  protected nav: AccordionDirective;

  constructor(@Inject(AccordionDirective) nav: AccordionDirective, private router:Router) {
    this.nav = nav;
  }

  ngOnInit(): any {
    this.nav.addLink(this);
  
  }
  ngAfterViewInit():any{
  // console.log("directivechild=",this.children);
  
  }

  ngOnDestroy(): any {
    this.nav.removeGroup(this);
  }

  toggle(): any {
    /*for slimscroll on and off*/
    document.querySelector('.pcoded-inner-navbar').classList.add('scroll-sidebar');

    this.open = !this.open;
  }
}
