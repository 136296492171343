import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { IsAdmin } from './shared/services/is-admin.service';
import { IsPEAdmin } from './shared/services/is-peadmin.service';
import { IsLogin } from './shared/services/is-login.service';
import { IsPort } from './shared/services/is-port.service';
import { PendingChangesGuard } from './shared/services/pending-changes.guard';
import { MedicalPlanComponent } from './theme/medical-plan/medical-plan.component';





const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'profile',
        loadChildren: './theme/user-profile/user-profile.module#UserProfileModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'user-management',
        loadChildren: './theme/user-management/user-management.module#UserManagementModule'
        , canActivate: [AuthGuard, IsPEAdmin]
      },
     
      {
        path: 'company',
        loadChildren: './theme/company-information/company-information.module#CompanyInformationModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'broker',
        loadChildren: './theme/broker-information/broker-information.module#BrokerInformationModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'healthcare-plans',
        loadChildren: './theme/healthcare-plans/healthcare-plans.module#HealthcarePlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'medical-plans',
        // component: MedicalPlanComponent,
        loadChildren: './theme/medical-plan/medical-plan.module#MedicalPlanModule'
        , canActivate: [AuthGuard, IsPort]
        
      },
      {
        path: 'dental-plans',
        loadChildren: './theme/dental-plan/dental-plan.module#DentalPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'vision-plans',
        loadChildren: './theme/vision-plan/vision-plan.module#VisionPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'ancillary-plans',
        loadChildren: './theme/ancillary-plans/ancillary-plans.module#AncillaryPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'basic-life_add-plan',
        loadChildren: './theme/basic-life_add-plan/basic-life_add-plan.module#BasicLifeADDPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'emp-dependent-plan',
        loadChildren: './theme/emp-dependent-plan/emp-dependent-plan.module#EmpDependentPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'shortterm-disability-plan',
        loadChildren: './theme/shortterm-disability-plan/shortterm-disability-plan.module#ShortTermDisabilityPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'longterm-disability-plan',
        loadChildren: './theme/longterm-disability-plan/longterm-disability-plan.module#LongTermDisabilityPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'travel-benefit-plans',
        loadChildren: './theme/travel-benefit-plans/travel-benefit-plans.module#TravelPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'business-travel-accident-plan',
        loadChildren: './theme/business-travel-accident-plan/business-travel-accident-plan.module#BusinessTravelAccidentPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'US-expatriate-plan',
        loadChildren: './theme/us-expatriate-plan/us-expatriate-plan.module#USExpatriateBenefitPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'voluntary-plans',
        loadChildren: './theme/voluntary-plans/voluntary-plans.module#VoluntaryPlansModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'executive-benefit-plans',
        loadChildren: './theme/executive-benefit-plans/executive-benefit-plans.module#ExecutiveBenefitPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'key-person-life-disability-plan',
        loadChildren: './theme/key-person-life-disability-plan/key-person-life-disability-plan.module#KeyPersonLifeDisabilityPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'executive-life-disability-plan',
        loadChildren: './theme/executive-life-disability-plan/executive-life-disability-plan.module#ExecutiveLifeDisabilityPlanModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'group-personal-excess-liability',
        loadChildren: './theme/group-personal-excess-liability/group-personal-excess-liability.module#GroupPersonalExcessLiabilityModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'executive-health-exam',
        loadChildren: './theme/executive-health-exam/executive-health-exam.module#ExecutiveHealthExamModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'administrators-service-providers',
        loadChildren: './theme/administrators-service-providers/administrators-service-providers.module#AdministratorsServiceProvidersModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'benefit-plan-configuration',
        loadChildren: './theme/benefit-plan-configuration/benefit-plan-configuration.module#BenefitPlanConfigurationModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'benefit-plan-configuration-admin',
        loadChildren: './theme/benefit-plan-configuration-admin/benefit-plan-configuration-admin.module#BenefitPlanConfigurationAdminModule'
        , canActivate: [AuthGuard, IsAdmin]
      },
      {
        path: 'metadata',
        loadChildren: './theme/metadata/metadata.module#MetaDataModule'
        , canActivate: [AuthGuard, IsAdmin]
      },
      {
        path: 'premium-analysisreport',
        loadChildren: './theme/premium-analysis-report/premium-analysis-report.module#PremiumAnalysisModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'executive-report',
        loadChildren: './theme/executive-report/executive-report.module#ExecutiveReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'vendor-analysis-core-report',
        loadChildren: './theme/vendor-analysis-core-report/vendor-analysis-core-report.module#VendorAnalysisCoreReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'vendor-analysis-vbeb-report',
        loadChildren: './theme/vendor-analysis-vbeb-report/vendor-analysis-vbeb-report.module#VendorAnalysisVbebReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'vendor-analysis-services-report',
        loadChildren: './theme/vendor-analysis-services-report/vendor-analysis-services-report.module#VendorAnalysisServicesReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'renewal-analysis-report',
        loadChildren: './theme/renewal-analysis-report/renewal-analysis-report.module#RenewalAnalysisReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'key-vendor-premium-report',
        loadChildren: './theme/key-vendor-premium-report/key-vendor-premium-report.module#KeyVendorReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'broker-fee-analysis-report',
        loadChildren: './theme/broker-fee-analysis-report/broker-fee-analysis-report.module#BrokerFeeAnalysisReportModule'
        , canActivate: [AuthGuard, IsPEAdmin]
      },
      {
        path: 'broker-analysis2-report',
        loadChildren: './theme/broker-analysis2-report/broker-analysis2-report.module#BrokerAnalysisReportModule'
        , canActivate: [AuthGuard, IsPEAdmin]
      },
      {
        path: 'stewardship-report',
        loadChildren: './theme/stewardship-report/stewardship-report.module#StewardshipReportModule'
        , canActivate: [AuthGuard, IsPEAdmin]
      },
      {
        path: 'summary-by-product-report',
        loadChildren: './theme/summary-by-product-report/summary-by-product-report.module#SummaryByProductModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'saving-target-report',
        loadChildren: './theme/saving-target-report/saving-target-report.module#SavingTargetReportModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'Broker-Consultant-Compensation',
        loadChildren: './theme/broker-commission-information/broker-commission-information.module#BrokerCommissionInformationModule'
        , canActivate: [AuthGuard, IsPort]
      },
      {
        path: 'pc-dashboard',
        loadChildren: './theme/pc-dashboard/pc-dashboard.module#PCDashboardModule',
        canActivate: [AuthGuard, IsPort],
      },
      {
        path: 'pc-welcome',
        loadChildren: './theme/pc-welcome/pc-welcome.module#PCWelcomeModule',
        canActivate: [AuthGuard, IsPort],
      },
      
      {
        path: 'portfolio-companies',
        loadChildren: './theme/portfolio-companies/portfolio-companies.module#PortfolioCompaniesModule'
        , canActivate: [AuthGuard, IsAdmin]
        
      },
      {
        path: '',
        redirectTo: 'pc-dashboard',
        canActivate: [AuthGuard, IsPort],
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, IsPort],
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'admin',
        loadChildren: './theme/user/user.module#UserModule',
        canActivate: [AuthGuard, IsAdmin]
      },
      {
        path: 'PEadmin',
        loadChildren: './theme/user/user-pe/user-pe.module#UserPEModule',
        canActivate: [AuthGuard, IsPEAdmin]
      },
      // {
      //   path: 'admin/pe/:id',
      //   loadChildren: './theme/user/user-card/user-card.module#UserCardModule'
      // },
      {
        path: 'navigation',
        loadChildren: './theme/navigation/navigation.module#NavigationModule'
      },
      {
        path: 'widget',
        loadChildren: './theme/widget/widget.module#WidgetModule'
      },
      {
        path: 'basic',
        loadChildren: './theme/ui-elements/basic/basic.module#BasicModule'
      },
      {
        path: 'advance',
        loadChildren: './theme/ui-elements/advance/advance.module#AdvanceModule'
      },
      {
        path: 'animations',
        loadChildren: './theme/ui-elements/animation/animation.module#AnimationModule'
      },
      {
        path: 'forms',
        loadChildren: './theme/forms/forms.module#FormsModule'
      },
      {
        path: 'bootstrap-table',
        loadChildren: './theme/table/bootstrap-table/bootstrap-table.module#BootstrapTableModule'
      },
      {
        path: 'data-table',
        loadChildren: './theme/table/data-table/data-table.module#DataTableModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/coming-soon',
        loadChildren: './theme/maintenance/coming-soon/coming-soon.module#ComingSoonModule'
      },
      {
        path: 'user',
        loadChildren: './theme/user/user.module#UserModule'
      },
      {
        path: 'email',
        loadChildren: './theme/email/email.module#EmailModule'
      },
      {
        path: 'task',
        loadChildren: './theme/task/task.module#TaskModule'
      },
      {
        path: 'crm-contact',
        loadChildren: './theme/crm-contact/crm-contact.module#CrmContactModule'
      },
      {
        path: 'invoice',
        loadChildren: './theme/extension/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'file-upload-ui',
        loadChildren: './theme/extension/file-upload-ui/file-upload-ui.module#FileUploadUiModule'
      },
      {
        path: 'calendar',
        loadChildren: './theme/extension/event-calendar/event-calendar.module#EventCalendarModule'
      },
      {
        path: 'charts',
        loadChildren: './theme/chart/chart.module#ChartModule'
      },
      {
        path: 'map',
        loadChildren: './theme/map/map.module#MapModule'
      },
      {
        path: 'simple-page',
        loadChildren: './theme/simple-page/simple-page.module#SimplePageModule'
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './theme/basic-login/basic-login.module#BasicLoginModule',
        canActivate: [IsLogin]
      },
      {
        path: 'forgot',
        loadChildren: './theme/forgot/forgot.module#ForgotModule'
      },
      {
        path: 'create',
        loadChildren: './theme/setpas/setpas.module#SetpasModule'
      },
      {
        path: 'reset',
        loadChildren: './theme/reset/reset.module#ResetModule'
      },
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      },
      {
        path: 'landing',
        loadChildren: './theme/landing/landing.module#LandingModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
