import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { Router } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { HttpService } from '../../shared/services/http.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
// import { PEFirmUserService } from "@services/services/pefirm.service";

import swal from 'sweetalert2';
import { blankValidator } from "@app/shared/services/blankValidator";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { UploadFileService } from '@app/shared/services/upload-file.service';

@Component({
  selector: 'app-custom',
  templateUrl: './custom-pe.component.html',
  styleUrls: ['./custom-pe.component.scss',
    '../../../assets/icon/simple-line-icons-master/css/simple-line-icons.css'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])]
})
export class CustomPeComponent implements OnInit, AfterViewInit {

  @Input() obj;
  @Input() metadata;
  @Input() countries;
  @Input() relationshipManager;
  @Input() isPEAdminNewCompanyCard=false;
  @Input() isPEAdminNewCompanyCardObj;
  
  
  selectedFiles: FileList;
  digitMask = createNumberMask({
    prefix: '$',
    suffix: '' // This will put the dollar sign at the end, with a space.
  })
  percentageMask = createNumberMask({
    prefix: '',
    suffix: '%' // This will put the dollar sign at the end, with a space.
  })
  mask=this.digitMask;
  @ViewChild('infoTab') infoTab;

  @ViewChild('contactsTab') contactsTab;
  @ViewChild('pename') pename;
  @ViewChild('contactname') contactname;
  @Output() updatePEFirmCustomEvent = new EventEmitter();
  @Output() addContactCustomEvent = new EventEmitter();
  @Output() updateContactCustomEvent = new EventEmitter();
  @Output() deleteContactCustomEvent = new EventEmitter();
  @Output() deletePEProfileCustomEvent = new EventEmitter();

  fullScreenValue: boolean = false;
  saveBtnVisibility: boolean;

  personalContactsData;


  @Output() firstChildClosedEvent = new EventEmitter();

  contactAddEditForm: FormGroup;
  peFirmBasicInfoEditForm: FormGroup;
  contactOpenFlag=false;
  editOpenFlag=false;
  logoImg = 'assets/images/no_image.jpg';
  constructor(private fBuilder: FormBuilder, 
    private UploadFileService:UploadFileService,
    public router: Router, private menuItems: MenuItems, private http: HttpService) {
    this.http.getData().subscribe(data => {
      this.personalContactsData = this.obj.all_contact_person;
      this.setInvestmentAmountLabelEdit(this.obj);
      this.setInvestmentAmountMask(this.obj.investment_name);

      //   // this.personalContactsData = data;
    });

    this.peFirmBasicInfoEditForm = this.fBuilder.group(
      {
        name: ['', Validators.compose([Validators.required, blankValidator])],
        status: ['', [Validators.required]],
        relationship_manager: ['', [Validators.required]],
        logo: [''],
        comments: [''],
        investment_amount: [''],
        investment_id: [''],
        other_investment_type: [''],
        id: [''],
        Contacts: this.contactVar,
      });

    this.contactAddEditForm = this.fBuilder.group(
      {
        name: ['', Validators.compose([Validators.required, blankValidator])],
        position_id: ['', [Validators.required]],
        phone: [''],
        email: ['', [Validators.required, CustomValidators.email]],
        address: [''],
        is_primary_contact: [false],
        is_deal_guy: [false],
        is_invited: [false],
        type: ["2"],
        id: [''],
        position: [''],
        newFlag:['']
      });
  }

  ngOnInit() {
    this.isPEAdminNewCompanyCardObj.isFirmCard = true
    if(this.obj.logo) {
      this.logoImg = this.obj.logo
    }
  }


  ngAfterViewInit() {

  }

  moveToDetails(id) {
    // alert(id);
    this.router.navigate(["/admin/pe/1"]);
  }

  cardIsFull(full) {
    if (full) {
      this.fullScreenValue = true;
    }
    else {
      this.infoTab.select('infotab-selectbyid1');
      this.contactsTab.select('contacttab-selectbyid1');
      this.fullScreenValue = false;
    }
  }

  public async updateUser() {

    // this.spinner.show();
    console.log(this.peFirmBasicInfoEditForm.value);
    if (this.selectedFiles) {
      if (this.selectedFiles.length !== 0) {
        const file = this.selectedFiles.item(0);
        var file_response = (await this.UploadFileService.uploadfile(file));
        this.peFirmBasicInfoEditForm.controls.logo.setValue(file_response.Key);
      }
    }
    this.updatePEFirmCustomEvent.emit(this.peFirmBasicInfoEditForm.value);
   
    
   
    

    // console.log(JSON.stringify(this.userDataPost));
    // this.webapi.makeRequest('Pefirm/Update', this.peFirmBasicInfoEditForm.value).subscribe(
    //     response => {
    //         console.log(response.status);
    //         console.log("post response");
    //         console.log(response);
    //         this.spinner.hide();
    //         this.toast.success({ title: "User updated successfully", timeout: 5000, theme: 'bootstrap' });

    //     },
    //     error => { console.log(error); }
    // );
  }

  closeCard(event) {
    this.firstChildClosedEvent.emit(event);
  }
  otherInvestmentTypeFlag = false;
  setOtherInvestmentType(data) {
    console.log(data);
    var index = this.metadata.investment_type.fields.find(cc => cc.id == data);
    this.setInvestmentAmountLabel(index);
    this.setInvestmentAmountMask(index.label);
    if (index.is_other == 1) {
      this.peFirmBasicInfoEditForm.get('other_investment_type').setValidators([Validators.required]);
      this.peFirmBasicInfoEditForm.get('other_investment_type').updateValueAndValidity();
      this.otherInvestmentTypeFlag = true;
    }
    else {
      this.otherInvestmentTypeFlag = false;
      this.peFirmBasicInfoEditForm.get('other_investment_type').clearValidators();
      this.peFirmBasicInfoEditForm.get('other_investment_type').setValue("");
      this.peFirmBasicInfoEditForm.get('other_investment_type').updateValueAndValidity();

    }
    console.log(index);
  }
  deletePE(event) {
    console.log("delete in custom pe component");
    console.log(event)
    this.deletePEProfileCustomEvent.emit(event);
  }

  closeForm(tab, isFromInfo, isFromContact) {
    if (isFromInfo)
      this.infoTab.select(tab);
    else if (isFromContact)
      this.contactsTab.select(tab);
  }
  editPE(obj) {
    if(obj.logo) {
      var t = (obj.logo.split('?')[0]).split('/')
      obj.logo = t[t.length-1]
    }
    // console.log(event);
    console.log("event");
    this.editPEBasicInfo(obj);
    this.editOpenFlag=true;
  }
  InviteDisable;
  addEditContact(ele, saveBtnVisibility) {
    this.contactAddEditForm.reset();
    this.contactAddEditForm.controls.is_deal_guy.setValue(false);
    this.contactAddEditForm.controls.is_invited.setValue(false);
    this.contactAddEditForm.controls.is_primary_contact.setValue(false);
    console.log(ele);

    if (ele != null) {
      this.contactAddEditForm.patchValue(ele);
      if (ele.is_invited == 1 && ele.newFlag==0) {
        this.InviteDisable = true;
      }
      else
        this.InviteDisable = false;
    }
    else
      this.InviteDisable = false;


    if (saveBtnVisibility)
      this.saveBtnVisibility = true;
    else
      this.saveBtnVisibility = false;

    this.contactsTab.select('contacttab-selectbyid2');
    setTimeout(() => {
      this.contactname.nativeElement.focus();
      console.log("timeout")
    }, 1000);
  }

  submitContactForm() {

  }

  openConfirmsSwal(id) {
    swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#B8080F',
      cancelButtonColor: '#464545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        console.log(id);
        this.deleteContactCustomEvent.emit(id);
        // this.personalContactsData.splice(this.personalContactsData.findIndex(x => x.id == id), 1);
      }
    }).catch(function (error) { console.log(error) });
  }

  saveContact(formData) {
    this.addContactCustomEvent.emit(formData);

    // console.log(formData);
    // this.personalContactsData.push(formData);

    // this.contactsTab.select('contacttab-selectbyid1');
  }

  Addelement(data) {
    this.personalContactsData.push(data);
  }

  updateContact(formData) {
    this.updateContactCustomEvent.emit(formData);
  }

  editPEBasicInfo(ele) {
    console.log("edit data")
    console.log(ele)
    this.peFirmBasicInfoEditForm.patchValue(ele);
    this.setInvestmentAmountLabelEdit(ele);
    // console.log(ele);
    this.setInvestmentAmountMask(ele.investment_name);
    if (ele.other_investment_type != null) {
      console.log("came in other block");
      this.otherInvestmentTypeFlag = true;
    }
    else
      this.otherInvestmentTypeFlag = false;

    //code for contacts
    this.contactVar.splice(0);
    if (ele.all_contact_person.length > 0) {
      ele.all_contact_person.forEach((element, index) => {
        element["newFlag"]=0;
        this.contactVar.push(element);
      });
    }
    console.log(this.contactVar)
    this.contactRows=[...this.contactVar];
    this.peFirmBasicInfoEditForm.controls.Contacts.setValue(this.contactVar);
    console.log(this.contactVar)


    this.infoTab.select('infotab-selectbyid2');
    setTimeout(() => {
      this.pename.nativeElement.focus();
      console.log("timeout")
    }, 1000);
  }
  InvestmentAmountLabel="Investment Amount";
  setInvestmentAmountLabel(data){
    console.log(data);
    if(data.label.toLowerCase()=="fixed fee" || data.label.toLowerCase()=="fixed fee + commission"){
      this.InvestmentAmountLabel="Fixed Fee";
    }
    else
    this.InvestmentAmountLabel="Investment Amount";

  }
  setInvestmentAmountLabelEdit(data){
    console.log(data);
    if(data.investment_name){
      if(data.investment_name.toLowerCase()=="fixed fee" || data.investment_name.toLowerCase()=="fixed fee + commission"){
        this.InvestmentAmountLabel="Fixed Fee";
      }
      else
      this.InvestmentAmountLabel="Investment Amount";
    }

  }

  setInvestmentAmountMask(type){
    if(type!="" && type!=null){

    
    if(type.toLowerCase()=="% of savings"){
      this.mask=this.percentageMask;
    }else{
      this.mask=this.digitMask;
    }
  }
  }
  public closeIconClicked(){
    console.log("closeiconclicked");
    this.editOpenFlag=false;
    this.contactOpenFlag=false;
  }

  contactRows;
  contactVar: any[] = []; 
  overseasDivisionChangeFlag=false;
  saveContactNew(){
    console.log(this.contactVar);
    console.log(this.contactAddEditForm.value);
    var positionindex=this.metadata.pefirm_position.fields.findIndex(x => x.id == this.contactAddEditForm.controls.position_id.value)
      console.log('index=',positionindex);
    console.log(this.metadata.portfolio_position.fields)
    this.contactAddEditForm.controls.position.setValue(this.metadata.pefirm_position.fields[positionindex].label);
    if(this.contactAddEditForm.controls.id.value){

     var index=this.contactRows.findIndex(x => x.id == this.contactAddEditForm.controls.id.value)
      console.log('index=',index)
      this.contactRows[index]=this.contactAddEditForm.value;
      this.contactVar[index]=this.contactAddEditForm.value;
      this.contactRows=[...this.contactRows];
      this.contactVar=[...this.contactVar];
    }
    else{
     this.contactAddEditForm.controls.id.setValue(+new Date);
     this.contactAddEditForm.controls.newFlag.setValue(1);
      this.contactVar.push(this.contactAddEditForm.value);
      this.contactRows=[...this.contactVar];
    }
    
    this.contactAddEditForm.reset();
    this.contactAddEditForm.controls.id.setValue("");
    this.peFirmBasicInfoEditForm.controls.Contacts.setValue(this.contactVar);
    console.log(this.contactVar);
  }
  deleteContactRow(id) {
    swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#B8080F',
      cancelButtonColor: '#464545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.overseasDivisionChangeFlag=true;
        console.log(id);
        this.contactRows.splice(this.contactRows.findIndex(x => x.id == id), 1);
        this.contactVar.splice(this.contactVar.findIndex(x => x.id == id), 1);
        this.contactRows=[...this.contactRows];
      }
    }).catch(function (error) { console.log(error) });
  }

  selectFile(event) {
    console.log(event.target.files)
    // if (event.target.files[0]['type'] == 'application/vnd.ms-excel' || event.target.files[0]['type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.selectedFiles = event.target.files;
      // this.formGroup.controls.shapelist.setValue("");
    // }
    
  
  }
}
