import { Injectable } from '@angular/core';

@Injectable()
export class WindowScrolling {

    // private styleTag: HTMLStyleElement;

    constructor(){
        // this.styleTag = this.buildStyleElement();
    }

    public disable() : void {

        document.body.classList.add('o-hidden');

    }

    public enable() : void {

            document.body.classList.remove('o-hidden');

    }

    // private buildStyleElement() : HTMLStyleElement
    // {

    //     var style = document.createElement( "style" );

    //     style.type = "text/css";
    //     style.setAttribute( "data-debug", "Injected by WindowScrolling service." );
    //     style.textContent = `
    //         body {
    //             overflow: hidden !important ;
    //         }
    //     `;

    //     return( style );

    // }
}