import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NextPreviousComponent} from "./next-previous.component"; 

import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';

import { ToastyModule } from 'ng2-toasty';
import { UiSwitchModule } from 'ng2-ui-switch';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ArchwizardModule } from 'ng2-archwizard/dist';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule, Routes } from '@angular/router';

// import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpClientModule,
    UiSwitchModule,
    ToastyModule.forRoot(),
    NgxDatatableModule,
    ArchwizardModule,
    NgbDatepickerModule,
    TextMaskModule,
    RouterModule



  ],
  declarations: [NextPreviousComponent],
  exports: [NextPreviousComponent]
})
export class NextPreviousModule { }
